import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
const modulesRoutes: Array<RouteRecordRaw> = []
const modulesFiles = require.context('./modules', false, /.+\.ts$/)
modulesFiles.keys().forEach((modulePath: string) => {
  modulesFiles(modulePath).default && modulesRoutes.push(...modulesFiles(modulePath).default)
})
const routes: Array<RouteRecordRaw> = [
  ...modulesRoutes,
  {
    path: '/',
    name: 'Home',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '@/views/pc/Home.vue'),
      p: () => import(/* webpackChunkName: "about" */ '@/views/pc/Home.vue'),
      m: () => import(/* webpackChunkName: "about" */ '@/views/mobile/Home.vue'),
    }
  },
  {
    path: '/news',
    name: 'News',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '@/views/pc/News.vue'),
      p: () => import(/* webpackChunkName: "about" */ '@/views/pc/News.vue'),
      m: () => import(/* webpackChunkName: "about" */ '@/views/mobile/News.vue')
    }

  },
  {
    path: '/login',
    name: 'Login',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '@/views/pc/Login.vue'),
      p: () => import(/* webpackChunkName: "about" */ '@/views/pc/Login.vue'),
      m: () => import(/* webpackChunkName: "about" */ '@/views/mobile/Login.vue'),
    }
  },
  {
    path: '/recruitment',
    name: 'Recruitment',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '@/views/pc/Recruitment.vue'),
      p: () => import(/* webpackChunkName: "about" */ '@/views/pc/Recruitment.vue'),
      m: () => import(/* webpackChunkName: "about" */ '@/views/mobile/Recruitment.vue'),
    },
    meta: {
      title: '到门口代理招募官网',
    },
  },
  {
    path: '/introduce',
    name: 'Introduce',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '@/views/pc/Introduce.vue'),
      p: () => import(/* webpackChunkName: "about" */ '@/views/pc/Introduce.vue'),
      m: () => import(/* webpackChunkName: "about" */ '@/views/mobile/Introduce.vue'),
    },

  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '@/views/pc/NewsDetail.vue'),
      p: () => import(/* webpackChunkName: "about" */ '@/views/pc/NewsDetail.vue'),
      m: () => import(/* webpackChunkName: "about" */ '@/views/mobile/NewsDetail.vue'),
    },
  },
  {
    path: '/dmkServer',
    name: 'dmkServer',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '@/views/pc/service/DmkServer.vue'),
      p: () => import(/* webpackChunkName: "about" */ '@/views/pc/service/DmkServer.vue'),
      m: () => import(/* webpackChunkName: "about" */ '@/views/pc/service/DmkServer.vue')
    },
  },

  {
    path: '/dmkClient',
    name: 'dmkClient',
    component: () => import(/* webpackChunkName: "about" */ '@/views/pc/service/DmkClient.vue')
  },
  {
    path: '/moduleIntroduce',
    name: 'moduleIntroduce',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pc/introduction/ModuleIntroduce.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pc/login/Register.vue')
  },
  {
    path: '/dmkPrivacy',
    name: 'Privacy',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/components/pc/pdf/DmkPrivacy.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/components/pc/pdf/DmkPrivacy.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/components/pc/pdf/DmkPrivacy.vue'),
    },
  },
  {
    path: '/agreement',
    name: 'Agreement',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/components/pc/pdf/Agreement.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/components/pc/pdf/Agreement.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/components/pc/pdf/Agreement.vue'),
    },
  },
  {
    path: '/settle',
    name: 'Settle',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/views/pc/settle/Index.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/views/pc/settle/Index.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/views/pc/settle/Index.vue'),
    },
  },
  {
    path: '/cultural',
    name: 'Cultural',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/CulturalTourism.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/CulturalTourism.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/CulturalTourism.vue'),
    },
  },
  {
    path: '/ecological',
    name: 'Ecological',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/EcologicalAgriculture.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/EcologicalAgriculture.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/EcologicalAgriculture.vue'),
    },
  },
  {
    path: '/physical',
    name: 'Physical',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/PhysicalIndustry.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/PhysicalIndustry.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/PhysicalIndustry.vue'),
    },
  },
  {
    path: '/ecommerce',
    name: 'Ecommerce',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/ECommerce.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/ECommerce.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/ECommerce.vue'),
    },
  },
  {
    path: '/transportation',
    name: 'transportation',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/ExpressTransportation.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/ExpressTransportation.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/components/pc/operations/ExpressTransportation.vue'),
    },
  },


  {
    path: '/test',
    name: 'test',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/views/pc/test/Test.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/views/pc/test/Test.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/views/pc/test/Test.vue'),
    },
  },
  {
    path: '/personInfo',
    name: 'personInfo',
    components: {
      default: () => import(/* webpackChunkName: "about" */  '@/views/pc/person/Index.vue'),
      p: () => import(/* webpackChunkName: "about" */  '@/views/pc/person/Index.vue'),
      m: () => import(/* webpackChunkName: "about" */  '@/views/mobile/Person.vue'),
    },
  }

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to: any, from: any, next: any) => {
  if (to.meta.title != undefined) {
    document.title = to.meta.title
  } else {
    document.title = "到门口集团官方网站"
  }
  if (to.path === '/introduce') {
    window.sessionStorage.setItem('menuType', JSON.stringify(3))
    return next()
  }

  if (to.path === '/newsDetail') {
    window.sessionStorage.setItem('menuType', JSON.stringify(2))
    return next()
  }
  if (to.path === '/recruitment') {
    window.sessionStorage.setItem('menuType', JSON.stringify(4))
    return next()
  }
  if (to.path === '/news') {
    window.sessionStorage.setItem('menuType', JSON.stringify(2))
    return next()
  }
  if (to.path === '/login') {
    window.sessionStorage.setItem('menuType', JSON.stringify(5))
    return next()
  }
  if (to.path === '/register') {
    window.sessionStorage.setItem('menuType', JSON.stringify(5))
    return next()
  }
  if (to.path === '/') {
    window.sessionStorage.setItem('menuType', JSON.stringify(1))
    return next()
  }
  if (to.path === '/dmkServer') {
    return next()
  }




  if (to.path === '/personInfo') {
    let localAccountStorage: any = window.localStorage.getItem('Authorization')
    let sessionPhoneStorage: any = window.sessionStorage.getItem('phone')
    try {
      if (JSON.parse(localAccountStorage).token !== null) {
        return next()
      }
    } catch (error) {
      try {
        if (JSON.parse(sessionPhoneStorage).phone !== null) {
          return next()
        }
      } catch (error) {
        return next('/login')
      }
      return next('/login')
    }
    return next('/login')
  }

  next();
})

export default router
