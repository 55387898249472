import {
    SET_DIALOG_TYPE,
    SET_MESSAGE_TYPE,
    SET_INFO_USER,
    SELECT_MENU_TYPE,
    SET_PASSWORD,
    SET_LOGIN_TYPE,
    SET_PERSON_INFO
} from "@/store/mutation-types"


export const app = {
    state: {
        dialog: {
            type: 0,
            data: false
        },
        message: {
            type: 0,
            data: false
        },
        userInfo: false,
        updatePassword: false,
        menuType: 1,
        loginType: 1,
        personInfo: {
            id: '', //会员id
            phone: '',
            nickname: '',  // 昵称
            realname: '',  // 真实姓名
            avatar: '',
            birthday: '',
            sex: 0,
            username: '',   // 唯一id Phone
            email: '',
            balance: 0, // 用户余额
            addressDetail: '', //地址
            frozenPoints: '', // 冻结积分
            frozenBalance: '',  //冻结余额
        }
    },
    mutations: {
        SET_DIALOG_TYPE: (state: any, value: any) => {
            state.dialog = value;
        },
        SET_MESSAGE_TYPE: (state: any, value: any) => {
            state.message = value;
        },
        SET_INFO_USER: (state: any, value: any) => {
            state.userInfo = value;
        },
        SET_PASSWORD: (state: any, value: any) => {
            state.updatePassword = value;
        },
        SELECT_MENU_TYPE: (state: any, value: any) => {
            state.menuType = value;
        },
        SET_LOGIN_TYPE: (state: any, value: any) => {
            state.loginType = value;
        },
        SET_PERSON_INFO: (state: any, value: any) => {
            state.personInfo = value;
        },
    },
    actions: {
        setDialog({ commit }: any, value: any) {
            commit(SET_DIALOG_TYPE, value)
        },
        setMessage({ commit }: any, value: any) {
            commit(SET_MESSAGE_TYPE, value)
        },
        setUserInfo({ commit }: any, value: any) {
            commit(SET_INFO_USER, value)
        },
        selectMenuType({ commit }: any, value: any) {
            commit(SELECT_MENU_TYPE, value)
        },
        setPassword({ commit }: any, value: any) {
            commit(SET_PASSWORD, value)
        },
        setLoginType({ commit }: any, value: any) {
            commit(SET_LOGIN_TYPE, value)
        },
        setPersonInfo({ commit }: any, value: any) {
            commit(SET_PERSON_INFO, value)
        },

    },
    getters: {
        dialog: (state: any) => state.dialog,
        message: (state: any) => state.message,
        userInfo: (state: any) => state.userInfo,
        menuType: (state: any) => state.menuType,
        updatePassword: (state: any) => state.updatePassword,
        loginTye: (state: any) => state.loginTye,
        personInfo: (state: any) => state.personInfo,
    }
}